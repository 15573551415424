import { FunctionComponent } from "react";
import rsLogoLight from "../assets/logo/rs-logo-light.svg";

export interface IUnAuthenticated {
  text?: string;
  isPreview?: boolean;
}

const UnAuthenticated: FunctionComponent<IUnAuthenticated> = ({
  text,
  isPreview,
}) => {
  const defaultMessage = !isPreview
    ? "No content found!"
    : "Please edit the app settings to get started";

  return (
    <div className="flex w-full h-[100vh] justify-center items-center bg-main-80 flex-col flex-wrap">
      <img src={rsLogoLight} className="w-[25vw]" alt="logo" />
      <p className="text-main-0 text-[1.5vw] mt-3">{text || defaultMessage}</p>
    </div>
  );
};

export default UnAuthenticated;
